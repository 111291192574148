import { useEffect, useState } from "react";
import { Client, Account, ID, Models, Databases, Query } from "appwrite";
import { Routes, Route, useParams } from "react-router-dom";
import { Wheel } from "react-custom-roulette";
import "./App.css";
import { Chip, Container, Divider, Stack } from "@mui/material";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import jingle from "./jingle-bells-orchestra_56sec-172985.mp3";
import wheel from "./bike-back-wheel-coasting-74816.mp3";
import useSound from "use-sound";

const LOTS_COLLECTION_ID = "63852d8319c0ef25be99";
const USERS_COLLECTION_ID = "6385439eae463d01012f";
const DATABASE_ID = "63852bd44f811ce2f0e3";

const client = new Client()
  .setEndpoint("https://api.romeczek.pl/v1") // Your API Endpoint
  .setProject("63852a9abcb4eb297baf"); // Your project ID

const account = new Account(client);
const databases = new Databases(client);

function Home() {
  return (
    <>
      <main>
        <h2>:)</h2>
      </main>
    </>
  );
}

interface Lot extends Models.Document {
  name: string;
  kid: boolean;
}

interface User extends Models.Document {
  name: string;
  so: string;
  blacklist: string[];
}
const segments = [
  "Hania",
  "Janusz",
  "Teresa",
  "Zbyszek",
  "Romek",
  "Monika",
  "Bartek",
  "Martyna",
  "Karol",
  "Klaudia",
  "Szymon",
  "Ania",
  "Marta",
  "Stachu",
  "Natalka",
  "Staś",
  "Emilka",
  "Leon",
  "Marianka",
  "Wojtek",
  "Filip",
  "Damian",
  "Kajtek",
];

function Draw() {
  const [play] = useSound(jingle, { volume: 0.25, loop: true });
  const [playWheel, { stop }] = useSound(wheel, { volume: 1 });
  let { token = "" } = useParams<"token">();

  const [session, setSession] = useState<boolean>();
  const [user, setUser] = useState<User>();
  const [userLots, setUserLots] = useState<Lot[]>([]);
  const [uncoveredLots, setUncoveredLots] = useState<Lot[]>([]);
  const [mustStartSpinning, setMustStartSpinning] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(-1);

  useEffect(() => {
    if (uncoveredLots.length < userLots.length) {
      const l = userLots[uncoveredLots.length];
      setPrizeNumber(segments.findIndex((s) => s === l.name));
    }
  }, [userLots, uncoveredLots]);

  // useEffect(() => {
  //   if (session) return;

  // }, [session]);

  useEffect(() => {
    if (!session) return;

    databases
      .listDocuments<User>(DATABASE_ID, USERS_COLLECTION_ID, [
        Query.equal("$id", token),
      ])
      .then((resp) => {
        const { documents } = resp;
        const [u] = documents;
        setUser(u);
      });
  }, [session, token]);

  useEffect(() => {
    if (!user) return;

    (async () => {
      const { documents: ads } = await databases.listDocuments<Lot>(
        DATABASE_ID,
        LOTS_COLLECTION_ID,
        [Query.equal("ownerId", user.$id), Query.equal("kid", false)]
      );
      const { documents: ks } = await databases.listDocuments<Lot>(
        DATABASE_ID,
        LOTS_COLLECTION_ID,
        [
          Query.equal("ownerId", [user.$id, ...(user.so ? [user.so] : [])]),
          Query.equal("kid", true),
        ]
      );
      setUserLots([...ks, ...ads]);
      setUncoveredLots([...ks, ...ads].filter((l) => l.drawn));
    })();
  }, [user]);

  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
  ];
  const onStopSpinning = () => {
    stop();
    setMustStartSpinning(false);
    if (prizeNumber > -1) {
      const lot = userLots.find((l) => l.name === segments[prizeNumber]);
      if (lot) {
        (async () => {
          await databases.updateDocument<Lot>(
            DATABASE_ID,
            LOTS_COLLECTION_ID,
            lot.$id,
            { drawn: true }
          );
        })();
        setUncoveredLots([...uncoveredLots, lot]);
      }
    }
  };

  const onClick = () => {
    if (
      mustStartSpinning ||
      uncoveredLots.length === userLots.length ||
      prizeNumber === -1
    ) {
      return;
    }
    playWheel();
    setMustStartSpinning(true);
  };

  const onStart = () => {
    play();
    account
      .get()
      .then((s) => {
        if (s) {
          setSession(true);
          return;
        }
        account.createAnonymousSession().then((s) => setSession(true));
      })
      .catch(() => {
        account.createAnonymousSession().then((s) => setSession(true));
      });
  };

  const startAgain = () => {
    setUncoveredLots([]);
  };

  return (
    <>
      {!user && (
        <Box
          margin={20}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingButton variant="outlined" onClick={onStart}>
            Zaczynamy
          </LoadingButton>
        </Box>
      )}
      {user && (
        <Stack spacing={1}>
          <Box>
            <h2>Cześć {`${user.name}`}</h2>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Wheel
              mustStartSpinning={mustStartSpinning}
              onStopSpinning={onStopSpinning}
              prizeNumber={prizeNumber}
              spinDuration={1.5}
              data={segments.map((s) => ({ option: s }))}
              backgroundColors={segColors}
              textColors={["#ffffff"]}
            />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <LoadingButton
              // disabled={
              //   uncoveredLots.length === userLots.length || prizeNumber === -1
              // }
              variant="outlined"
              onClick={
                uncoveredLots.length === userLots.length ? startAgain : onClick
              }
            >
              {uncoveredLots.length === userLots.length
                ? "Jeszcze raz!"
                : "Losuj"}
            </LoadingButton>
          </Box>
          <Box>
            {user.so && (
              <h4>
                <span style={{ color: "red" }}>*</span>Wylosowane dzieci są
                wspólne na parę
              </h4>
            )}
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={0}
              sx={{ flexWrap: "wrap", gap: 1 }}
            >
              {uncoveredLots.map((k) => (
                <Chip key={k.name} label={k.name} />
              ))}
            </Stack>
          </Box>
        </Stack>
      )}
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:token" element={<Draw />} />
      </Routes>
    </div>
  );
}

export default App;
